import { DateObject } from "react-multi-date-picker";
import HijriCalendar from "react-date-object/calendars/arabic";
import HijriArabicLocalization from "react-date-object/locales/arabic_ar";
import HijriEnglishLocalization from "react-date-object/locales/arabic_en";
import GregorianCalendar from "react-date-object/calendars/gregorian";
import GregorianArabicLocalization from "react-date-object/locales/gregorian_ar";
import { useCookieContext } from "contexts";
import { useTranslation } from "react-i18next";

const dateFormats = {
  withTime: "D/M/YYYY h:mm:ss a",
  onlyDate: "D/M/YYYY",
};

const useSystemDateTheme = () => {
  const { isAr, isHijri } = useCookieContext();
  const { t } = useTranslation(["Common"]);

  const toGregorian = (gregorianDate?: string | Date, onlyDate = false): string => {
    const format = onlyDate ? dateFormats.onlyDate : dateFormats.withTime;

    return new DateObject({
      date: gregorianDate,
      calendar: GregorianCalendar,
      locale: isAr ? GregorianArabicLocalization : undefined,
    }).format(format);
  };

  const toHijri = (gregorianDate?: string | Date, onlyDate = false): string => {
    const format = onlyDate ? dateFormats.onlyDate : dateFormats.withTime;

    const date = new DateObject({
      date: gregorianDate,
      locale: isAr ? HijriArabicLocalization : HijriEnglishLocalization,
    });

    date.convert(HijriCalendar);

    return date.format(format);
  };

  const toSystemThemeDateFormat = (date?: string | Date, withOutTime = true): string => {
    return isHijri ? toHijri(date, withOutTime) : toGregorian(date, withOutTime);
  };

  const formatRelativeDateWithLocalization = (dateInput: Date | string) => {
    const now = new DateObject({
      calendar: isHijri ? HijriCalendar : GregorianCalendar,
      locale: isAr
        ? isHijri
          ? HijriArabicLocalization
          : GregorianArabicLocalization
        : isHijri
        ? HijriEnglishLocalization
        : undefined,
    });

    const date = new DateObject({
      date: new Date(dateInput),
      calendar: isHijri ? HijriCalendar : GregorianCalendar,
      locale: isAr
        ? isHijri
          ? HijriArabicLocalization
          : GregorianArabicLocalization
        : isHijri
        ? HijriEnglishLocalization
        : undefined,
    });

    const diffMinutes = Math.floor((now.toDate().getTime() - date.toDate().getTime()) / (1000 * 60));
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffMinutes / 1440);

    if (diffMinutes < 60) {
      return t("ago", { time: diffMinutes });
    } else if (diffDays < 1 && diffHours >= 1) {
      return t("hoursAgo", { time: diffHours });
    } else if (diffDays < 1) {
      return t("today");
    } else if (diffDays === 1) {
      return t("yesterday");
    } else if (diffDays < 7) {
      const weekday = date.format("dddd");
      const formattedDate = date.format("D MMMM");
      return t("weekday", { day: weekday, date: formattedDate });
    } else {
      return date.format("D MMMM YYYY");
    }
  };

  return { toSystemThemeDateFormat, formatRelativeDateWithLocalization };
};

export default useSystemDateTheme;
