import { ReactNode, useRef, useState } from "react";
import HeadersSection from "./HeadersSection";
import LoadingOverlay from "components/blocks/LoadingOverlay";

export type TabProps = {
  id: string;
  title: string;
  content: ReactNode;
};

export type PageHeaderProps = {
  title: string;
  value: ReactNode;
  renderCondition?: (selectedTabId: string) => boolean;
};

export type PageWithTabsProps = {
  title: string | ReactNode;
  titleImgSrc?: string;
  subTitle?: string | ReactNode | ReactNode[];
  containerClassName?: string;
  backButtonCallback?: (activeTabId: string) => void;
  actions?: (selectedTabId: string) => ReactNode;
  pageHeaders?: PageHeaderProps[];
  tapContentContainerClassName?: string;
  keepTabsMounted?: boolean;
  loading?: boolean;
} & (
  | {
      tapContent?: never;
      defaultTabId: string;
      tabs: (TabProps | null)[];
    }
  | {
      defaultTabId?: never;
      tabs?: never;
      tapContent: ReactNode;
    }
);

const PageWithTabs: React.FC<PageWithTabsProps> = (props) => {
  const [activeTabId, setActiveTabId] = useState(props.defaultTabId ?? "");
  const contentRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    contentRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      className="bg-body position-relative d-flex flex-column h-100 ps-2"
      style={{ margin: -20, minHeight: "calc(100% + 40px)" }}
    >
      <LoadingOverlay visible={!!props.loading} />
      <div
        className={`${
          props.containerClassName ? props.containerClassName : "d-flex flex-column bg-white rounded-4 p-4"
        } position-sticky z-1`}
        style={{ top: "-20px", border: "1px solid #f4f6f9" }}
      >
        <HeadersSection {...props} activeTabId={activeTabId} />
        {props.tabs && (
          <div className="d-flex gap-2 mt-4">
            {props.tabs?.map(
              (tab) =>
                tab && (
                  <button
                    key={tab.id}
                    className={`fs-14px px-3 py-2 rounded-4 btn btn-outline-primary  ${
                      tab.id === activeTabId ? " active fw-bold" : "border-light-300  text-dark"
                    }`}
                    onClick={() => {
                      scrollToTop();
                      setActiveTabId(tab.id);
                    }}
                  >
                    {tab.title}
                  </button>
                ),
            )}
          </div>
        )}
      </div>

      <div className="flex-grow-1 overflow-auto" ref={contentRef}>
        <div
          className={`${
            props.tapContentContainerClassName ? props.tapContentContainerClassName : "mt-3 p-4 bg-white rounded-4"
          }`}
        >
          {props.tabs ? (
            props.keepTabsMounted ? (
              props.tabs.map(
                (tab) =>
                  tab && (
                    <div key={tab.id} className={tab.id === activeTabId ? "" : "d-none"}>
                      {tab.content}
                    </div>
                  ),
              )
            ) : (
              props.tabs.find((tab) => tab?.id === activeTabId)?.content
            )
          ) : (
            <>{props.tapContent}</>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageWithTabs;
