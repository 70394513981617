import PageWithTabs from "components/PageWithTabs";
import { OrderInfoTab } from "./Tabs/OrderInfoTab";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import SubProceduresTab from "./Tabs/SubProceduresTab";
import AdditionalAttachmentsTab from "./Tabs/AdditionalAttachmentsTab";
import ProgramMinutesTab from "./Tabs/ProgramMinutesTab";
import SpeechesTab from "./Tabs/SpeechesTab";
import { useParams } from "react-router-dom";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useCookieContext } from "contexts";
import SendOrderToFundingUnitModal from "./OrderTaskActionModals/SendOrderToFundingUnitModal";
import ReturnOrderToReviewerModal from "./OrderTaskActionModals/ReturnOrderToReviewerModal";
import PageHeaders from "./PageHeaders";
import PageActions from "./PageActions";
import useOrderView from "./useOrderView";
import ResearcherCloseRequestPage from "./ResearcherCloseRequestPage";
import { EvaluateProgramUpdateRef } from "./Tabs/OrderInfoTab/components/Updates/useProgramOrderUpdateActions";
import { ApproveByCenterManagerSubmitHandlerRef } from "./PageActions/useCloseRequestActions";

type FormTabs = "programMinutesTab" | "additionalAttachmentsTab" | "speechesTab";
export type TapsIds = "orderInfoTab" | "subProceduresTab" | FormTabs;

export const tabsIds: {
  [key in TapsIds]: TapsIds;
} = {
  orderInfoTab: "orderInfoTab",
  programMinutesTab: "programMinutesTab",
  additionalAttachmentsTab: "additionalAttachmentsTab",
  subProceduresTab: "subProceduresTab",
  speechesTab: "speechesTab",
};

export const OrderActivePages = {
  Default: "Default",
  ResearchCloseRequest: "ResearchCloseRequest",
} as const;

export const OrderUserTypes = {
  ResearchCenterManager: "ResearchCenterManager",
  Admin: "Admin",
  FundingUnit: "FundingUnit",
  Researcher: "Researcher",
  DeanOfScientificResearch: "DeanOfScientificResearch",
  ResearchCenterManagerForOrderUpdate: "ResearchCenterManagerForOrderUpdate",
  ResearchCenterManagerForResearcherExtendRequest: "ResearchCenterManagerForResearcherExtendRequest",
  ResearchCenterManagerForResearcherApologiesRequest: "ResearchCenterManagerForResearcherApologiesRequest",
  ResearchCenterManagerForResearcherUpdateRequest: "ResearchCenterManagerForResearcherUpdateRequest",
  ResearcherEditProgramOrderTask: "ResearcherEditProgramOrderTask",
  DeanOfScientificResearchForResearcherApologiesRequest: "DeanOfScientificResearchForResearcherApologiesRequest",
  DeanOfScientificResearchForResearcherExtendRequest: "DeanOfScientificResearchForResearcherExtendRequest",
  ResearcherSendUpdateResponseAfterReturnRequest: "ResearcherSendUpdateResponseAfterReturnRequest",
  ResearcherSendApologizeResponseAfterReturnRequest: "ResearcherSendApologizeResponseAfterReturnRequest",
  ResearcherSendExtendResponseAfterReturnRequest: "ResearcherSendExtendResponseAfterReturnRequest",
  ResearchCenterManagerQueryRequest: "ResearchCenterManagerQueryRequest",
  ResearcherSendQueryResponse: "ResearcherSendQueryResponse",
  //! Close Request
  ResearchCenterManagerForCloseRequest: "ResearchCenterManagerForCloseRequest",
  FundingUnitForCloseRequest: "FundingUnitForCloseRequest",
  VPofDeanForCloseRequest: "VPofDeanForCloseRequest",
  FundFinanceForCloseRequest: "FundFinanceForCloseRequest",
  DeanOfScientificResearchForCloseRequest: "DeanOfScientificResearchForCloseRequest",
  FinanceDepartmentForCloseRequest: "FinanceDepartmentForCloseRequest",
  ResearcherSendCloseResponseAfterReturnRequest: "ResearcherSendCloseResponseAfterReturnRequest",
} as const;

const OrderView = ({ userType }: { userType: keyof typeof OrderUserTypes }) => {
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { t } = useTranslation(["Orders", "Shared"]);
  const { serialGID, orderId } = useParams();
  const { isAr } = useCookieContext();
  const programUpdateHandlersRef = useRef<EvaluateProgramUpdateRef>(null);
  const approveByCenterManagerSubmitHandlerRef = useRef<ApproveByCenterManagerSubmitHandlerRef>(null);

  const { loading, openedTask, order, programOrderTaskState, orderProgram } = useOrderView({
    serialGID,
    userType,
    orderId: Number(orderId),
  });

  const [activePage, setActivePage] = useState<keyof typeof OrderActivePages>(
    userType !== OrderUserTypes.ResearcherSendCloseResponseAfterReturnRequest ? "Default" : "ResearchCloseRequest",
  );
  const [modalProps, setModalProps] = useState<{
    orderId?: number;
    isOpen: boolean;
    type?: "return" | "respond"; // TODO: 2 types of return - check figma before and after sending to reviewers
  }>({
    orderId: undefined,
    isOpen: false,
    type: undefined,
  });

  const tapsFormRefs = useRef<{
    [key in FormTabs]: React.RefObject<HTMLFormElement>;
  }>({
    additionalAttachmentsTab: useRef<HTMLFormElement>(null),
    programMinutesTab: useRef<HTMLFormElement>(null),
    speechesTab: useRef<HTMLFormElement>(null),
  });

  const onSave = (selectedTabId: TapsIds) => {
    const form = tapsFormRefs.current![selectedTabId as FormTabs]?.current;
    if (form) {
      form.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
      form.classList.add("was-validated");
    }
  };

  const onActionClicked = (action: "return" | "respond") => {
    setModalProps({ isOpen: true, type: action, orderId: programOrderTaskState!.orderId });
  };

  return (
    <>
      <div className={`${activePage === OrderActivePages.Default ? "" : "d-none"}`}>
        <PageWithTabs
          title={
            programOrderTaskState
              ? orderProgram?.programDetails.find((x) => x.locale === (isAr ? "Ar" : "En"))?.displayName
              : `${t("order")} ${order?.serialNumber ?? ""}`
          }
          subTitle={
            programOrderTaskState
              ? `${t("programNumber")}: ${orderProgram?.serialNumber ?? ""}`
              : `${t("appliedOn")}: ${toSystemThemeDateFormat(order?.createdOn)}`
          }
          defaultTabId={tabsIds.orderInfoTab}
          loading={loading}
          pageHeaders={PageHeaders({
            order: order,
            programOrderTaskState,
          })}
          actions={(selectedTabId) => (
            <PageActions
              order={order}
              onActionClicked={onActionClicked}
              openedTask={openedTask}
              onSave={onSave}
              programOrderTaskState={programOrderTaskState}
              selectedTabId={selectedTabId}
              userType={userType}
              programOrderId={programOrderTaskState?.orderId ?? 0}
              serialGuid={serialGID ?? ""}
              programUpdateHandlersRef={programUpdateHandlersRef}
              approveByCenterManagerSubmitHandlerRef={approveByCenterManagerSubmitHandlerRef}
              program={orderProgram}
            />
          )}
          tabs={[
            {
              id: tabsIds.orderInfoTab,
              title: t("orderInfo"),
              content: (
                <OrderInfoTab
                  order={order}
                  orderProgram={orderProgram}
                  userType={userType}
                  activePage={activePage}
                  setActivePage={setActivePage}
                  openedTask={openedTask}
                  programUpdateHandlersRef={programUpdateHandlersRef}
                  approveByCenterManagerSubmitHandlerRef={approveByCenterManagerSubmitHandlerRef}
                />
              ),
            },
            {
              id: tabsIds.programMinutesTab,
              title: t("programMinutes"),
              content: (
                <ProgramMinutesTab
                  ref={tapsFormRefs.current[tabsIds.programMinutesTab as FormTabs]}
                  programOrderId={order?.id ?? 0}
                />
              ),
            },
            {
              id: tabsIds.speechesTab,
              title: t("speeches"),
              content: (
                <SpeechesTab
                  ref={tapsFormRefs.current[tabsIds.speechesTab as FormTabs]}
                  programOrderId={order?.id ?? 0}
                />
              ),
            },
            {
              id: tabsIds.additionalAttachmentsTab,
              title: t("additionalAttachments"),
              content: (
                <AdditionalAttachmentsTab
                  programOrderId={order?.id ?? 0}
                  ref={tapsFormRefs.current[tabsIds.additionalAttachmentsTab as FormTabs]}
                />
              ),
            },
            {
              id: tabsIds.subProceduresTab,
              title: t("subProcedures"),
              content: <SubProceduresTab orderId={order?.id ?? 0} />,
            },
          ]}
        />

        <ReturnOrderToReviewerModal
          isOpen={modalProps.isOpen && modalProps.type === "return"}
          isInspectMode={order?.isInspectMode}
          onClose={() => {
            setModalProps({ isOpen: false });
          }}
          orderId={modalProps.orderId ?? 0}
          serialGuid={serialGID}
        />

        <SendOrderToFundingUnitModal
          isOpen={modalProps.isOpen && modalProps.type === "respond"}
          onClose={() => {
            setModalProps({ isOpen: false });
          }}
          orderId={modalProps.orderId ?? 0}
          serialGuid={serialGID}
        />
      </div>

      {activePage === OrderActivePages.ResearchCloseRequest && (
        <ResearcherCloseRequestPage
          userType={userType}
          activePage={activePage}
          order={order}
          orderProgram={orderProgram}
          setActivePage={setActivePage}
          openedTask={openedTask}
          evaluateProgramUpdateRef={programUpdateHandlersRef}
          serialGuid={serialGID ?? ""}
          approveByCenterManagerSubmitHandlerRef={approveByCenterManagerSubmitHandlerRef}
        />
      )}
    </>
  );
};

export default OrderView;
