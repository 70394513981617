import MultiDatePicker, { DateObject } from "react-multi-date-picker";
import HijriCalendar from "react-date-object/calendars/arabic";
import GregorianCalendar from "react-date-object/calendars/gregorian";
import HijriArabicCalendar from "react-date-object/locales/arabic_ar";
import HijiryEnglishCalendar from "react-date-object/locales/arabic_en";
import GregorianArabicCalendar from "react-date-object/locales/gregorian_ar";

import "./DatePicker.scss";
import { useCookieContext } from "contexts";

type DatePickerProps = {
  label: string;
  placeholder?: string;
  value?: string | Date | DateObject;
  onChange?: (date: Date | null) => void;
  wrapperClassName?: string;
  error?: string;
  labelClassName?: string;
};

// TODO: Icon positioning and Coloring (.icon-tabler-calendar-event)
const DatePicker: React.FC<DatePickerProps> = (props) => {
  const { label, placeholder, value, onChange, wrapperClassName, error, labelClassName } = props;

  const { isAr, isHijri } = useCookieContext();

  const onLocalChange = (e: DateObject | null) => {
    onChange?.(e?.toDate() ?? null);
  };

  return (
    <div className={`${wrapperClassName}`}>
      <div className={`date-picker-wrapper position-relative ${error ? "is-invalid" : ""}`}>
        <label className={`mb-1 ${labelClassName ?? ""}`}>{label}</label>
        <MultiDatePicker
          placeholder={placeholder ?? label}
          value={typeof value === "string" ? new Date(value) : value}
          inputClass={`form-control ${error ? "is-invalid" : ""}`}
          containerClassName="w-100"
          inputMode="input"
          calendar={isHijri ? HijriCalendar : GregorianCalendar}
          onChange={onLocalChange}
          locale={
            isAr
              ? isHijri
                ? HijriArabicCalendar
                : GregorianArabicCalendar
              : isHijri
              ? HijiryEnglishCalendar
              : undefined
          }
        />
        <span className="icon-calendar text-primary fs-4" />
      </div>
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default DatePicker;
