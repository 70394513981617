import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useTranslation } from "react-i18next";
import { UserInformationResult } from "services/accountService/models/AccountType";

const ProfessionalInfo = ({ user }: { user?: UserInformationResult | null }) => {
  const { t } = useTranslation("UserProfile");
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  return (
    <div className="striped d-flex flex-column ">
      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 text-dark fw-bold">{t("userType")}</div>
        <div className="flex-2">{user?.type}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 text-dark fw-bold">{t("job")}</div>
        <div className="flex-2">{user?.job}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 text-dark fw-bold">{t("university")}</div>
        <div className="flex-2">{user?.university}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 text-dark fw-bold">{t("degree")}</div>
        <div className="flex-2">{user?.degreeNavigation?.degreeDetail.displayName}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 text-dark fw-bold">{t("college")}</div>
        <div className="flex-2">{user?.college}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 text-dark fw-bold">{t("department")}</div>
        <div className="flex-2">{user?.department}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 text-dark fw-bold">{t("generalSpecialization")}</div>
        <div className="flex-2">{user?.specialization}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 text-dark fw-bold">{t("specificSpecialization")}</div>
        <div className="flex-2">{user?.subspecialty?.subspecialtyByGeneralSpecializationDetail.displayName}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 text-dark fw-bold">{t("researchInterests")}</div>
        <div className="flex-2">{user?.userResearchInterests?.map((ele) => ele.researchInterest).join(",")}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 text-dark fw-bold">ORCID</div>
        <div className="flex-2">{user?.orcid}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 text-dark fw-bold">Google Scholar ID</div>
        <div className="flex-2">{user?.googleScholarId}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 text-dark fw-bold">{t("lastUpgradeDate")}</div>
        <div className="flex-2">{user?.lastUpgradeDate ? toSystemThemeDateFormat(user.lastUpgradeDate, true) : ""}</div>
      </div>
    </div>
  );
};

export default ProfessionalInfo;
