import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useShallow } from "zustand/react/shallow";
import GridView from "components/GridView";
import useGridStore from "components/GridView/grid.store";
import { useNotification } from "hooks/useNotification";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { AccreditationTypes } from "services/examiners/Model/ExaminerTypes";
import { useCookieContext } from "contexts";
import useFileDownload from "hooks/useFileDownload";
import { TasksService } from "services/tasks";
import { MyCompletedTaskResult } from "services/tasks/models/tasksTypes";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";

export default function ExaminersCompletedTasksGridView() {
  const { setBreadcrumbs } = useBreadcrumb();
  const { t } = useTranslation("ExaminersCompletedTasks");
  const { isAr } = useCookieContext();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { showNotification } = useNotification();
  const downloadFile = useFileDownload();

  const [loading, setLoading] = useState(false);
  const [allCompletedTasks, setAllCompletedTasks] = useState<MyCompletedTaskResult[] | undefined>();
  const [totalCount, setTotalCount] = useState(0);
  const ready = useRef(false);

  const { sortState, pagination, setPagination, searchValue, filters, resetGridStore } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
      filters: state.filters,
      resetGridStore: state.reset,
    })),
  );

  const fetchExaminerCompletedTasks = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await TasksService.getMyCompletedTasks({
        pageIndex: resetPagination ? 1 : pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: Object.keys(filters)
          .filter((key) => filters[key])
          .map((key) => ({
            field: key,
            value: filters[key],
            operator: "Contains",
          })),
      });

      setAllCompletedTasks(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchExaminerCompletedTasks();
  }, [pagination.currentPage]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchExaminerCompletedTasks({ resetPagination: true });
  }, [sortState, searchValue, filters, isAr]);

  useEffect(() => {
    if (!ready.current) {
      resetGridStore();
      ready.current = true;
      return;
    }
  }, [resetGridStore]);

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "examinerCompletedTasks",
        path: "/home/examinerCompletedTasks",
        menuItemId: "examinerCompletedTasks",
      },
    ]);
  }, []);

  const onExportClicked = async () => {
    setLoading(true);
    try {
      const response = await TasksService.exportAllMyCompletedTasksToExcel({
        pageIndex: pagination.currentPage,
        pageSize: 10,
        search: searchValue,
      });

      await downloadFile(response, "CompletedTasks.xlsx");
      setLoading(false);
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setLoading(false);
    }
  };

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "activityName",
        displayName: t("activityName"),
        filterable: true,
        showOnMobile: true,
      },
      {
        field: "workflowName",
        displayName: t("workflowName"),
        filterable: true,
      },
      {
        field: "startDateTime",
        accessor: "startDateTime",
        displayName: t("startDateTime"),
        render(row: MyCompletedTaskResult) {
          return <> {toSystemThemeDateFormat(row.startDateTime)}</>;
        },
        filterable: true,
        filterOptions: {
          type: "date",
        },
      },
      {
        field: "activityDescription",
        accessor: "activityDescription",
        displayName: t("activityDescription"),
      },
      {
        field: "status",
        accessor: "status",
        displayName: t("status"),
        showOnMobile: true,
        render(row: MyCompletedTaskResult) {
          return t(row.status);
        },
      },

      {
        field: "dateOfCompletionTheTask",
        accessor: "dateOfCompletionTheTask",
        displayName: t("dateOfCompletionTheTask"),
        render(row: MyCompletedTaskResult) {
          return row.dateOfCompletionTheTask ? <> {toSystemThemeDateFormat(row.dateOfCompletionTheTask)}</> : "-";
        },
        filterable: true,
        filterOptions: {
          type: "date",
        },
      },
      {
        field: "accreditationType",
        displayName: t("rewardConfirmation"),
        render(row: MyCompletedTaskResult) {
          return !row.accreditationType
            ? "-"
            : row.accreditationType === AccreditationTypes.Accreditation.label
            ? t("rewardConfirmed")
            : t("rewardRejected");
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("completedTasks"),
    singularName: t("task"),
    totalCount: totalCount,
    hideAddButton: false,
    onExportClick: onExportClicked,
  };

  return (
    <GridView
      loading={loading}
      gridProps={{
        data: allCompletedTasks ?? [],
        gridSchema,
      }}
      viewHeaderProps={viewHeader}
    />
  );
}
