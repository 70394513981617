import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import { useTranslation } from "react-i18next";
import PersonalInformation from "./PersonalInformation";
import ProfessionalInformation from "./ProfessionalInformation";
import BankingInformation from "./BankingInformation";
import { useForm } from "react-hook-form";
import { UpdateMyProfileRequest } from "services/accountService/models/AccountType";
import useMutation from "hooks/useMutation";
import { AccountService } from "services/accountService";
import { useEffect } from "react";
import { useQuery } from "hooks/useQuery";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCountryCodeAndPhoneByPhone, getCountryCodeByPhone } from "helpers/country-codes-helper";
import { getUpdateProfile } from "./updateProfile.schema";
import LoadingOverlay from "components/blocks/LoadingOverlay";

const EditUserProfileForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["UserProfile", "Common"]);
  const { userId } = useParams();
  const {
    control,
    register,
    reset,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UpdateMyProfileRequest>({
    defaultValues: {
      fullNameArabic: "",
      email: "",
      phoneNumber: "",
      workPhoneNumber: "",
      birthDate: "",
      address: "",
      city: "",
      phoneCountryCode: "",
      postCode: "",
      accountNumber: "",
      ibanNumber: "",
      swift: "",
      beneficiaryName: "",
      bankRoutingNumber: "",
      bankName: "",
      currencyId: undefined,
      accountTypeId: undefined,
      userResearchInterests: [],
      jobId: undefined,
      degree: "",
      university: "",
      college: "",
      department: "",
      specialization: "",
      employer: "",
      orcid: "",
      googleScholarId: "",
      lastUpgradeDate: "",
      degreeId: undefined,
      employeeId: "",
      fullNameEnglish: "",
      gender: "",
      genderId: 0,
      jobTitle: "",
      nationalId: "",
      nationality: "",
      subspecialtyId: undefined,
      username: "",
      workPhoneNumberCountryCode: "",
    },
    resolver: getUpdateProfile(t),
  });

  const { data, loading: getUserLoading } = useQuery({
    queryFn: async () => {
      return await AccountService.getUserByIdAsync(userId ?? "");
    },
    triggers: [userId],
  });

  const { mutateAsync, loading } = useMutation({
    queryFn: async (data: UpdateMyProfileRequest) => {
      return await AccountService.updateMyProfile(userId ?? "", data);
    },
  });

  const onSubmit = async (values: UpdateMyProfileRequest) => {
    const res = await mutateAsync(values);
    if (res && !res.hasError) {
      navigate(`/home/UserProfile?userId=${userId}`);
    }
  };

  useEffect(() => {
    if (data) {
      const { code, phone } = getCountryCodeAndPhoneByPhone(data.phoneNumber);
      const { code: workCode, phone: workPhone } = getCountryCodeAndPhoneByPhone(data.workPhoneNumber);
      reset({
        ...(data as any),
        fullNameArabic: data.arabicName,
        fullNameEnglish: data.englishName,
        userResearchInterests: data.userResearchInterests?.map((interest) => interest.researchInterest) ?? [],
        phoneNumber: phone,
        phoneCountryCode: code,
        workPhoneNumber: workPhone,
        workPhoneNumberCountryCode: workCode,
        specializationId: data.specializationId, // TODO: need Id
        specialization: data.specialization,
      });
    }
  }, [data]);

  return (
    <form noValidate className="d-flex flex-column gap-2 position-relative" onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay visible={getUserLoading} />
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <div className="fs-5 fw-bold">{data?.username ?? ""}</div>
          <div className="fs-6 text-gray">
            {t("employeeNumber")} : {data?.employeeId}
          </div>
        </div>

        <div className="d-flex gap-2">
          <button className="btn btn-primary px-4">
            {loading ? <div className="custom-loader"></div> : t("Common:save")}
          </button>
          <Link to={`/home/UserProfile?userId=${data?.id ?? 0}`}>
            <button className="btn border text-dark px-4">
              <span className="px-2">{t("Common:cancel")}</span>
            </button>
          </Link>
        </div>
      </div>
      <div className="divider"></div>

      <div
        className="d-flex flex-column gap-2 overflow-y-auto"
        style={{
          maxHeight: "calc(100svh - 250px)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Accordion>
          <AccordionItem initiallyOpen title={t("personalInformation")} elementId="PersonalInformation">
            <PersonalInformation errors={errors} control={control} register={register} setValue={setValue} />
          </AccordionItem>
        </Accordion>

        <Accordion>
          <AccordionItem initiallyOpen title={t("professionalInformation")} elementId="ProfessionalInformation">
            <ProfessionalInformation
              errors={errors}
              control={control}
              register={register}
              setValue={setValue}
              userResearchInterests={data?.userResearchInterests ?? []}
            />
          </AccordionItem>
        </Accordion>

        <Accordion>
          <AccordionItem initiallyOpen title={t("bankingInformation")} elementId="BankingInformation">
            <BankingInformation
              watch={watch}
              errors={errors}
              control={control}
              register={register}
              setValue={setValue}
            />
          </AccordionItem>
        </Accordion>
      </div>
    </form>
  );
};

export default EditUserProfileForm;
