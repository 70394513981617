import { useCookieContext } from "contexts";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";

export interface DropdownItemType extends Record<string, any> {
  value: string;
  id: string | number;
}

export type DropdownProps = {
  label?: string;
  data: DropdownItemType[];
  noSelectionPlaceholder?: string;
  wrapperClassName?: string;
  value?: string | number;
  onChange?: (value: string, item: DropdownItemType) => void;
  disabled?: boolean;
  formProps?: any;
  selectClassName?: string;
  changeEffect?: (value?: any) => void;
  error?: string;
  labelClassName?: string;
  withOutMinWidth?: boolean;
  readOnly?: boolean;
  withAsterisk?: boolean;
  defaultValue?: string | number;
  wrapperStyle?: React.CSSProperties;
  useReactSelect?: boolean;
};

/**
 * @param {DropdownProps} props
 * @param {boolean} props.useReactSelect - If true, the component will use react-select instead of the default select element and formProps is ignored
 * @returns {React.FC}
 */
const Dropdown: React.FC<DropdownProps> = (props) => {
  const {
    label,
    data,
    noSelectionPlaceholder,
    wrapperClassName,
    value,
    onChange,
    disabled,
    selectClassName,
    formProps,
    changeEffect,
    error,
    labelClassName,
    withOutMinWidth,
    readOnly,
    withAsterisk,
    defaultValue,
    wrapperStyle,
    useReactSelect,
  } = props;

  const { isRTL } = useCookieContext();
  const { t } = useTranslation();

  const onLocalChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange?.(e.target.value, data.find((item) => `${item.id}` === e.target.value)!);
    formProps?.onChange?.(e);
    changeEffect?.(e.target.value);
  };

  const onLocalReactSelectChange = (selectedOption: SingleValue<DropdownItemType>) => {
    onChange?.(`${selectedOption?.id}`, selectedOption as DropdownItemType);
    changeEffect?.(selectedOption?.id);
  };

  return (
    <div
      className={`${wrapperClassName}`}
      style={
        !withOutMinWidth
          ? { minWidth: 150, ...(wrapperStyle ? wrapperStyle : {}) }
          : { ...(wrapperStyle ? wrapperStyle : {}) }
      }
    >
      {label && (
        <div className={`mb-1 ${labelClassName} position-relative`}>
          {withAsterisk && (
            <span
              className={`position-absolute ${!isRTL ? "start-0" : "end-0"} top-0  text-danger`}
              style={{
                fontSize: "0.75rem",
              }}
            >
              *
            </span>
          )}
          <label className={`${withAsterisk ? "px-2" : ""}`}>{label}</label>
        </div>
      )}
      {useReactSelect ? (
        <Select
          placeholder={noSelectionPlaceholder ?? label}
          value={data.find((item) => item.id === value)!}
          onChange={onLocalReactSelectChange}
          options={data}
          getOptionLabel={(option) => option.value}
          noOptionsMessage={() => t("Common:noData")}
        />
      ) : (
        <select
          value={value}
          className={`form-select form-control ${selectClassName} ${error ? "is-invalid" : ""} ${
            readOnly ? "bg-white" : ""
          }`}
          aria-label={label}
          disabled={disabled || readOnly}
          {...formProps}
          onChange={onLocalChange}
          defaultValue={defaultValue}
        >
          <option value="" className="text-muted" disabled>
            {noSelectionPlaceholder ?? label}
          </option>
          {data.map((item) => (
            <option key={item.id} value={item.id}>
              {item.value}
            </option>
          ))}
        </select>
      )}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default Dropdown;
